const GENRELIST = [
  "rock",
  "electronic",
  "alternative",
  "indie",
  "pop",
  "metal",
  "alternative rock",
  "jazz",
  "classic rock",
  "ambient",
  "experimental",
  "folk",
  "punk",
  "indie rock",
  "hard rock",
  "hip-hop",
  "instrumental",
  "singer-songwriter",
  "black metal",
  "dance",
  "progressive rock",
  "death metal",
  "heavy metal",
  "hardcore",
  "soul",
  "chillout",
  "electronica",
  "classical",
  "industrial",
  "soundtrack",
  "rap",
  "blues",
  "punk rock",
  "thrash metal",
  "acoustic",
  "metalcore",
  "psychedelic",
  "post-rock",
  "progressive metal",
  "funk",
  "hip hop",
  "new wave",
  "trance",
  "house",
  "reggae",
  "trip-hop",
  "post-punk",
  "techno",
  "electro",
  "indie pop",
  "rnb",
  "country",
  "power metal",
  "downtempo",
  "emo",
  "post-hardcore",
  "doom metal",
  "oldies",
  "psychedelic rock",
  "gothic metal",
  "synthpop",
  "russian",
  "gothic",
  "grunge",
  "britpop",
  "swedish",
  "noise",
  "screamo",
  "lounge",
  "grindcore",
  "nu metal",
  "j-pop",
  "polish",
  "pop rock",
  "chill",
  "blues rock",
  "avant-garde",
  "new age",
  "ska",
  "drum and bass",
  "progressive",
  "shoegaze",
  "darkwave",
  "pop punk",
  "dubstep",
  "canadian",
  "ebm",
  "world",
  "folk metal",
  "easy listening",
  "deathcore",
  "j-rock",
  "industrial metal",
  "alternative metal",
  "gothic rock",
  "hardcore punk",
  "disco",
  "latin",
  "lo-fi",
  "dub",
  "atmospheric",
  "stoner rock",
  "folk rock",
  "drone",
  "christian",
  "celtic",
  "sludge",
  "garage rock",
  "contemporary classical",
  "electropop",
  "smooth jazz",
  "dream pop",
  "psytrance",
  "fusion",
  "art rock",
  "underground hip-hop",
  "soft rock",
  "acid jazz",
  "swing",
  "australian",
  "neofolk",
  "irish",
  "baroque",
  "k-pop",
  "speed metal",
  "korean",
  "neoclassical",
  "alt-country",
  "rockabilly",
  "rock n roll",
  "glam rock",
  "progressive trance",
  "synth pop",
  "video game music",
  "space rock",
  "americana",
  "rock and roll",
  "melodic hardcore",
  "noise rock",
  "atmospheric black metal",
  "breakbeat",
  "dark electro",
  "emocore",
  "goth",
  "krautrock",
  "composer",
  "nu jazz",
  "world music",
  "post rock",
  "post-metal",
  "southern rock",
  "rhythm and blues",
  "smooth",
  "russian rock",
  "mathcore",
  "club",
  "medieval",
  "breakcore",
  "melodic metal",
  "mpb",
  "glitch",
  "industrial rock",
  "trip hop",
  "rapcore",
  "deep house",
  "r&b",
  "indie folk",
  "bossa nova",
  "opera",
  "dancehall",
  "gangsta rap",
  "progressive house",
  "experimental rock",
  "pagan metal",
  "groove metal",
  "progressive death metal",
  "dnb",
  "dreamy",
  "crust",
  "eurodance",
  "melodic black metal",
  "vocal trance",
  "drum n bass",
  "symphonic black metal",
  "electroclash",
  "hiphop",
  "vocal jazz",
  "jrock",
  "math rock",
  "good",
  "jazz fusion",
  "african",
  "nu-metal",
  "avantgarde",
  "psychobilly",
  "crossover",
  "hair metal",
  "melodic",
  "post-grunge",
  "goregrind",
];

exports.GENRELIST = GENRELIST;
